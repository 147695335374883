import styled from "styled-components";
import {
  BP,
  Rem,
} from "../../commons/Theme";

export const StyledRedeemTreeForm = styled.div`
  padding-top: ${Rem(0)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(0)};
  }
`;

export const StyledRedeemTreeFormSubmit = styled.div`
  position: relative;
  padding-top: ${Rem(30)};
  display: flex;
  justify-content: center;

  @media (${BP.ipad}) {
    padding-top: ${Rem(60)};
  }
`;

export const StyledRedeemTreeSuccessModal = styled.div`
  padding: ${Rem(30)};

  h1 {
    font-size: ${Rem(25)};
    padding-top: ${Rem(8)};
  }
  p {
    padding-bottom: ${Rem(32)};
  }
`;
